import React, { useEffect, useState } from 'react'
import { TextField } from '@material-ui/core'
import { useLocation, useNavigate } from 'react-router-dom'
import setAuthToken from '../../auth/SetAuthToken'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import InputAdornment from '@mui/material/InputAdornment'
import { toast, ToastContainer } from 'react-toastify'
import Alert from '@mui/material/Alert'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

// src
import './SignInPage.scss'
import { LoginBtn } from '../../assets/muiStyles/Button-style'
import { addRefreshToken, addToken, addUserId } from '../../redux/slices/authSlice/authSlice'
import { usePostSignInMutation } from '../../redux/api/authApi/authApi'
import { addRefreshTokenExpiration } from '../../redux/slices/globalValue/globalValue'

export default function SignInPage () {
  // hooks
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  // env
  const refresh_token_expiration = process.env.REACT_APP_REFRESH_TOKEN_EXPIRATION

  // redux
  const evSlug = useSelector(state => state.globalValueReducer.slug)
  const eventId = useSelector(state => state.globalValueReducer.eventId)
  const refresh_token_expirationRedux = useSelector(state => state.globalValueReducer.refresh_token_expiration)
  // state
  const [credientials, setCredientials] = useState({
    email: '',
    password: ''
  })
  // const [rememberMe, setRememberMe] = useState('false')
  const [errorText, setError] = useState('')
  const [passwordEye, setPasswordEye] = useState(false)
  const locationEdit = location.pathname.split('/')
  // query
  const [postSignIn, isLoading] = usePostSignInMutation(credientials)
  useEffect(() => {
    if (window.location.href.includes('/sign-in') || window.location.href.includes('/user/editing-profile/') && refresh_token_expirationRedux === 'expired') {
      refresh_token_expirationRedux && toast(<FormattedMessage id="session-expired-message"
                                defaultMessage="Your session has expired, please login again"/>, {
        toastId: 'session-expired-message'
      })
    }
  }, [])
  // useEffect(() => {
  //   error && toast.error(<FormattedMessage id="toast-error-invalid-credentials"
  //                                   defaultMessage="invalid credentials!"/>, {
  //     position: 'top-center'
  //   })
  // }, [error])
  const handleSubmit = async (e) => {
    e.preventDefault()
    if (credientials.email.length <= 0 || credientials.password.length <= 0) {
      return toast.error(<FormattedMessage id="toast-error-required" defaultMessage="Fields are required."/>, {
        position: 'top-center'
      })
    }
    postSignIn(credientials)
      .unwrap()
      .then((response) => {
        if (response && response.access_token) {
          dispatch(addToken(response.access_token))
          dispatch(addRefreshToken(response.refresh_token))
          dispatch(addUserId(response.user.pk))
          dispatch(addRefreshTokenExpiration(+new Date() + +refresh_token_expiration))
          setAuthToken(response.access_token)
          if (locationEdit[2] === 'editing-profile') {
            navigate('/user/editing-profile/')
          } else {
            eventId
              ? navigate('/events/' + eventId + '/' + evSlug + '/agenda/')
              : navigate('/')
          }
        }
      })
      .catch(() => {
        toast.error(<FormattedMessage id="toast-error-invalid-credentials"
                                                defaultMessage="invalid credentials!"/>, {
          position: 'top-center'
        })
      })
  }
  const handleChange = (e) => {
    setCredientials({ ...credientials, [e.target.name]: e.target.value })
  }

  return (
        <>
             <ToastContainer/>
            <div className="sign-in-main">
                <form onSubmit={handleSubmit} noValidate>
                    <div className="sign-in-form">
                        <p className="welcome-msg"><FormattedMessage id="welcome-back" defaultMessage="Welcome Back"/>
                        </p>
                        {/* <p className="desc"> */}
                        {/*    <FormattedMessage id="sign-in-enter-msg" */}
                        {/*                      defaultMessage="Enter your credentials to access your account"/> */}
                        {/* </p> */}
                        <FormattedMessage id="placeholder-enter-email" defaultMessage="Enter your email address">
                            {
                                placeholder =>
                                    <TextField
                                        style={{ marginTop: '50px' }}
                                        error={!!errorText}
                                        // helperText= 'Please Enter Your Email'
                                        value={credientials.email}
                                        onFocus={() => setError('')}
                                        onChange={(e) => handleChange(e)}
                                        placeholder={`${placeholder}`}
                                        type="email"
                                        name="email"
                                        variant="outlined"
                                        required
                                    />
                            }
                        </FormattedMessage>

                        <FormattedMessage id="placeholder-enter-password1" defaultMessage="Geben Sie Ihren Anmelde-Code ein">
                            {
                                placeholder =>
                                    <TextField
                                        style={{ marginTop: '24px', borderRadius: '8px' }}
                                        error={!!errorText}
                                        onFocus={() => setError('')}
                                        // helperText= 'Please Enter Your Email'
                                        value={credientials.password}
                                        onChange={(e) => handleChange(e)}
                                        name="password"
                                        // inputProps={{ pattern: "^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$"}}
                                        placeholder={`${placeholder}`}
                                        type={!passwordEye ? 'password' : 'text'}
                                        variant="outlined"
                                        InputProps={{
                                          endAdornment: (
                                                <InputAdornment position="end">
                                                    {passwordEye
                                                      ? (
                                                        <VisibilityIcon
                                                            onClick={() => setPasswordEye(!passwordEye)}
                                                        />
                                                        )
                                                      : (
                                                        <VisibilityOffIcon
                                                            onClick={() => setPasswordEye(!passwordEye)}
                                                        />
                                                        )}
                                                </InputAdornment>
                                          )
                                        }}
                                        required
                                    />
                            }
                        </FormattedMessage>

                        <br/>
                        {errorText
                          ? (
                            <Alert
                                className="error-message"
                                style={{}}
                                variant="outlined"
                                severity="error"
                            >
                                {errorText}
                            </Alert>
                            )
                          : (
                              ''
                            )}
                        {/* <div className="sign-in-option"> */}
                        {/*    <p */}
                        {/*        className="forgot-pass" */}
                        {/*        onClick={() => navigate('/user/register')} */}
                        {/*    > */}
                        {/*        <FormattedMessage id="register-me" defaultMessage="Not yet registered"/>? */}

                        {/*    </p> */}

                        {/*    <p */}
                        {/*        className="forgot-pass" */}
                        {/*        onClick={() => { */}
                        {/*          navigate('/password/forgot') */}
                        {/*        }} */}
                        {/*    > */}
                        {/*        <FormattedMessage id="forgot-password" defaultMessage="Forgot Password"/>? */}

                        {/*    </p> */}
                        {/* </div> */}
                        <LoginBtn
                            type="submit"
                            loading={isLoading}
                            variant="contained"
                        >
                            <FormattedMessage id="sign-in" defaultMessage="Sign In"/>
                        </LoginBtn>
                    </div>
                    <br/>
                </form>
            </div>
        </>
  )
}
