import React from 'react'
import colors from '../../../Colors.scss'

const QuestionSVG = ({ width = '30', height = '30' }) => {
  return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 0C5.4 0 0 5.4 0 12C0 18.6 5.4 24 12 24C18.6 24 24 18.6 24 12C24 5.4 18.6 0 12 0ZM13.35 19.5H10.35V16.5H13.35V19.5ZM16.5 12.15C15.9 12.75 15.3 13.05 14.7 13.2C13.8 13.8 13.5 13.5 13.5 15H10.5C10.5 12 12.3 11.1 13.5 10.5C13.95 10.35 14.25 10.2 14.55 9.9C14.7 9.75 15 9.45 14.7 8.85C14.4 8.1 13.5 7.35 12.15 7.35C10.05 7.35 9.75 9.15 9.6 9.6L6.6 9.15C6.75 7.5 8.1 4.35 12 4.35C14.4 4.35 16.5 5.7 17.4 7.65C18 9.3 17.7 10.95 16.5 12.15Z"
            fill={colors.color_white}
        />
        </svg>
  )
}

export default QuestionSVG
