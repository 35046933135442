import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'

// src
import './Banner.scss'
import noPhoto from '../../../images/noPhoto.jpg'
import { addEventId, addMenu, addSlug } from '../../../redux/slices/globalValue/globalValue'

const Banner = ({ bannerEvent }) => {
  // hooks
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // state
  let startTime, endTime
  try {
    startTime = bannerEvent?.items[0]?.schedule_begin
      .substring(0, 16)
      .split('T')[0].split('-').reverse().join('-') + ',' + bannerEvent?.items[0]?.schedule_begin
      .substring(0, 16)
      .split('T')[1]
  } catch (e) {
    startTime = 0
  }
  try {
    endTime = bannerEvent?.items[0]?.schedule_end
      .substring(0, 16)
      .split('T')[0].split('-').reverse().join('-') + ',' + bannerEvent?.items[0]?.schedule_end
      .substring(0, 16)
      .split('T')[1]
  } catch (e) {
    endTime = 0
  }

  const [livebtn, setLivebtn] = useState(0)

  useEffect(() => {
    const { items } = bannerEvent
    try {
      const currentTime = new Date().getTime()
      const begin = new Date(items[0]?.schedule_begin?.substring(0, 16))?.getTime()
      const end = new Date(items[0]?.schedule_end?.substring(0, 16))?.getTime()
      if (currentTime > begin && currentTime < end) {
        setLivebtn(1)
      } else if (currentTime < begin) {
        setLivebtn(2)
      } else {
        setLivebtn(3)
      }
    } catch (e) {
      setLivebtn(3)
    }
  }, [bannerEvent])

  return (
        <>
            {!!bannerEvent?.length && (
                <div
                    onClick={() => {
                      dispatch(addEventId({ eventId: bannerEvent[0]?.id }))
                      dispatch(addSlug(bannerEvent[0]?.meta?.slug))
                      dispatch(addMenu('agenda'))
                      navigate(
                        '/events/' +
                            bannerEvent[0]?.id +
                            '/' +
                            bannerEvent[0]?.meta?.slug +
                            '/agenda/',
                        {
                          state: { id: bannerEvent[0]?.id }
                        }
                      )
                    }}
                    className="homepage-container"
                >
                    {
                        bannerEvent[0]?.image?.meta?.download_url
                          ? <img
                                className="banner-img"
                                src={bannerEvent[0]?.image?.meta?.download_url}
                                width="100%"
                                height="100%"
                            />
                          : <img src={noPhoto}
                                 className="banner-img"
                                 width="100%"
                                 height="100%"
                                 alt="event-card"/>

                    }

                    <div className="banner-play-btn">
                        {/* <PlayBtn/> */}
                        <p className="black-rounded-btn-entry">Klicken Sie hier, um einzutreten</p>
                    </div>
                    <div className="details-container">
                        <div className="homepage-section-2-text">
                            {livebtn === 1
                              ? (
                                <button className="banner-live-now-btn">
                                    {' '}
                                    <svg
                                        width="8"
                                        height="8"
                                        viewBox="0 0 8 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <circle cx="4" cy="4" r="4" fill="white"/>
                                    </svg>
                                    <FormattedMessage id="live-now-uppercase" defaultMessage="LIVE NOW"/>
                                </button>
                                )
                              : livebtn === 2
                                ? (
                                <p className="time">
                                    {startTime +
                                        ' - ' +
                                        endTime}
                                </p>
                                  )
                                : livebtn === 3
                                  ? (
                                <p className="time ">
                                    Streamed On{' '}
                                    {bannerEvent[0]?.schedule_end?.substring(0, 16)}
                                </p>
                                    )
                                  : (
                                      ''
                                    )}
                            <p className="heading">{bannerEvent[0]?.title}</p>
                            <p className="para">{bannerEvent[0]?.hero_text}</p>
                        </div>
                    </div>
                </div>
            )}
        </>
  )
}
export default Banner
