import React from 'react'
import { FormattedMessage } from 'react-intl'
import UserPlaceholder from '../../../../SVG/pages/eventAgenda/UserPlaceholder'
import { useNavigate } from 'react-router-dom'

const PlaceholderText = ({ people, title, viewAllCount, eventId, slug, setViewAllCount }) => {
  const navigate = useNavigate()
  return (
            <div className="chair-card-container">
                <h2 className='agenda-font-speaker-title'>Referent:innen</h2>

                {people.length > 0 && people.map(
                  (sp, index) =>
                    index < viewAllCount && (
                            <div className="chair-card cursor-pointer"
                                 key={sp.id}
                                 onClick={() =>
                                   navigate(
                                         `/events/${eventId}/${slug}/participants/profile/`,
                                         {
                                           state: { profileId: sp.id }
                                         }
                                   )
                                 }
                            >
                                {sp?.avatar?.download_url ? <img
                                    className="cursor-pointer"
                                    key={sp.id}
                                    src={sp?.avatar?.download_url}
                                    alt={sp?.full_name}
                                    title={sp?.full_name}
                                    width="36px"
                                    height="36px"
                                /> : <UserPlaceholder onClick={() =>
                                  navigate(
                                        `/events/${eventId}/${slug}/participants/profile/`,
                                        {
                                          state: { profileId: sp.id }
                                        }
                                  )
                                } className="cursor-pointer" width='28' height="28"/>}
                                <p>{sp?.full_name}</p>
                            </div>
                    ))}
                {people.length > viewAllCount && (
                    <p
                        className="agenda-detai-viewall cursor-pointer"
                        onClick={() => {
                          setViewAllCount(people.length)
                        }}
                    >
                        <FormattedMessage id="view-all" defaultMessage="view all"/>
                    </p>
                )}
            </div>
  )
}

export default PlaceholderText
