import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

// React Intl
import { FormattedMessage } from 'react-intl'

// Material UI components
import Popover from '@mui/material/Popover'
import Avatar from '@mui/material/Avatar'
import PublicIcon from '@mui/icons-material/Public'

// Styles
import colors from '../../../Colors.scss'
import { GradiantBtn, LoginBtn } from '../../../assets/muiStyles/Button-style'

// Local Components
import AvatarComponent from '../../Avatart/Avatar'
import Presence from '../../Presence/Presence'

// SVG
import Briefcase from '../../../SVG/components/header/Briefcase'
import Vector from '../../../SVG/components/header/Vector'

// Actions
import { handleLogout } from '../../../helper/header/login'
import { useAuthValue } from '../../../hooks/redux/reduxHooks'

const HeaderActions = () => {
  // hooks
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // custom hooks
  const { profileDetails, userId } = useAuthValue()

  const [open, setOpen] = useState({ header: false, anchor: null })
  const openPopupHeader = Boolean(open.anchor)
  const id = open.anchor ? 'simple-popover' : undefined

  // function
  const handleClick = useCallback((event) => {
    setOpen({ header: false, anchor: event.currentTarget })
  }, [])

  const handleClose = useCallback(() => {
    setOpen({ header: false, anchor: null })
  }, [])

  return (
      <div className="header-page-actions">
          {/* <p><a href='mailto: hotline@ecc-app.com'><FormattedMessage id="support-us" defaultMessage="Support"/></a></p> */}
          {/* <p><a href='https://ecc-app.com/Complete_Guide_ECC-App.pdf' target='_blank' rel="noreferrer"><FormattedMessage id="guide-us" defaultMessage="Guide"/></a></p> */}
          {/* <p><a href='https://www.colorectalsurgery.eu/frontend/index.php?page_id=2691' target='_blank' rel="noreferrer"><FormattedMessage id="privacy-us" defaultMessage="Privacy Policy"/></a></p> */}
          {userId
            ? (
                  <>
                      <div className="header-login">
                          <div onClick={handleClick}>

                              <AvatarComponent alt="dp"
                                               url={profileDetails?.avatar?.download_url} status_online={profileDetails?.status_online}/>
                          </div>

                          <div onClick={handleClick}
                               style={{
                                 display: 'flex',
                                 justifyContent: 'start',
                                 flexDirection: 'column',
                                 paddingTop: '6px'
                               }}>
                              <div className="header-login__status">
                                  <h4 className='user-name-near-avatar'>{profileDetails?.full_name?.split(' ').join(' ')}</h4>
                                  <Presence status={profileDetails?.attendee_type}/>
                              </div>
                              <p className='userEmail'>{profileDetails?.email}</p>

                          </div>

                          <div>
                              <Popover
                                  id={id}
                                  open={openPopupHeader}
                                  anchorEl={open.anchor}
                                  onClose={handleClose}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                  }}
                              >
                                  <Avatar src={profileDetails?.avatar?.download_url === null ? '' : profileDetails?.avatar?.download_url}
                                          sx={{ width: 91, height: 91, margin: '13px 0 13px 30px' }}/>
                                  <div className='login-context'>
                                      <div className='context-title'>
                                          {profileDetails?.full_name?.split(' ').join(' ')?.length > 0 && (
                                              <div className='userName'>
                                                  {profileDetails?.full_name?.split(' ').join(' ')}
                                              </div>
                                          )}
                                          {profileDetails?.email?.length > 0 && (
                                              <div className='userEmail'>
                                                  {profileDetails?.email}
                                              </div>
                                          )}
                                      </div>
                                      <div className='context-list'>
                                          {profileDetails?.job_title?.length > 0 && (
                                              <div className='list-container'>
                                                  <Briefcase height={24} width={24} color={colors.color_blue}/>
                                                  <p id='job-title-inner'>{profileDetails?.job_title}</p>
                                              </div>
                                          )}
                                          {profileDetails?.institution?.length > 0 && (
                                              <div className='list-container'>
                                                  <Vector height={24} width={24}/>
                                                  <p>{profileDetails?.institution}</p>
                                              </div>
                                          )}
                                          {profileDetails?.country?.length === 0
                                            ? null
                                            : <div className='list-container'>
                                                  <PublicIcon/>
                                                  <p>{profileDetails?.country}</p>
                                              </div>
                                          }
                                      </div>
                                  </div>
                                  <div className='context-out'>
                                      <button
                                          style={{
                                            margin: '8px 30px 0',
                                            borderRadius: '8px',
                                            padding: '10px, 16px, 10px, 16px'

                                          }}
                                          onClick={() => {
                                            navigate('/user/editing-profile/')
                                            setOpen({ header: false, anchor: null })
                                          }}
                                          className="edit-profile"><FormattedMessage id="edit-profile"
                                                                                     defaultMessage="Edit profile"/>
                                      </button>
                                      <GradiantBtn
                                          className="context-out-btn"
                                          sx={{
                                            margin: '10px 30px',
                                            padding: '10px 15px',
                                            borderRadius: '8px'
                                          }}
                                          onClick={() => {
                                            handleLogout({ dispatch })
                                            navigate('/')
                                            setOpen({ header: false, anchor: null })
                                          }}

                                      > <FormattedMessage id="logout" defaultMessage="Logout"/></GradiantBtn>
                                  </div>
                              </Popover>
                          </div>
                      </div>
                  </>
              )
            : (
                  <>
                      <LoginBtn
                          onClick={() => {
                            navigate('/sign-in')
                            setOpen((prev) => ({ ...prev, header: false }))
                          }
                          }
                      >
                          <FormattedMessage id="login" defaultMessage="Login"/>
                      </LoginBtn>
                  </>
              )}
      </div>
  )
}

export default HeaderActions
