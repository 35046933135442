import React, { useEffect, useState } from 'react'
import Chats from './Chats/Chats'
import PublicChat from './Chats/PublicChat/PublicChat'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { Tab, Tabs } from '@mui/material'
import messages from '../../translate/inboxPage/inboxPage'
// src
import './InboxPage.scss'
import { connectToSB, ConnectUserToSendBird } from './SendBird/action'
import ChatIcon from '../../SVG/global/ChatIcon'
import colors from '../../Colors.scss'
import Questions from '../../SVG/pages/question/Questions'
import ContextHelp from '../../components/ContextHelp/ContextHelp'
import { useGetAttendeeProfileQuery } from '../../redux/api/authApi/authApi'
import { useGroupChannelHandler } from '../../hooks/sendBird/useChannelHandler'
import { useAuthValue, useSendBirdValue } from '../../hooks/redux/reduxHooks'
import { getAllCountChannel } from '../../helper/sendBird/getAllCountChannel'
import { addUnreadMsgCount } from '../../redux/slices/SendBirdSlice/SendBirdSlice'

export default function InboxPage () {
  // hooks
  const dispatch = useDispatch()
  const { channelHandler, refreshMessage } = useGroupChannelHandler()

  // state
  const [selectedTab, setSelectedTab] = useState('pr-chat')
  const [showMenu] = useState(true)
  const [connectedUserToSendBird, setConnectedUserToSendBird] = useState(false)
  const [userMsg, setUserMsg] = useState()
  const [sendBird, setSendBird] = useState()
  const [connecting, setConnecting] = useState(false)
  const [showModalHelper, setShowModalHelper] = useState({
    open: false,
    event: {}
  })
  // redux
  const { userId } = useAuthValue()
  const { userSendBird } = useSendBirdValue()

  // api
  const { data: profileDetails } = useGetAttendeeProfileQuery(userId)

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue)
  }

  useEffect(() => {
    connectToSB().then(value => setSendBird(value))
  }, [])

  useEffect(() => {
    if (!connecting && !connectedUserToSendBird && profileDetails && !!sendBird) {
      setConnecting(false)
      ConnectUserToSendBird(profileDetails, sendBird).then(value => {
        setConnectedUserToSendBird(value)
      }).finally(() => setConnecting(true))
    }
    if (connecting) {
      sendBird?.groupChannel?.addGroupChannelHandler(profileDetails?.userId + '', channelHandler)
    }
  }, [profileDetails, sendBird, connectedUserToSendBird])

  useEffect(() => {
    (async () => {
      const { allChannels, countUnreadMsg } = await getAllCountChannel({ sendBird, userSendBird, dispatch }) || {}
      setUserMsg(allChannels)
      dispatch(addUnreadMsgCount(+countUnreadMsg))
    })()
  }, [sendBird, refreshMessage, userSendBird])

  return (

        <>
            {/* <ToastContainer /> */}

            <div className="inbox-main">
                {showMenu && (
                    <div className="conversation-main">
                        <div className="title-main">
                            <div className="title">
                              <ChatIcon color={colors.color_cards_background} width='30' height='30'/><p><FormattedMessage
                                    id="conversations" defaultMessage="Conversations"/>&nbsp;
                            <span
                        onClick={(e) => {
                          setShowModalHelper({
                            open: !showModalHelper.open,
                            event: e.currentTarget
                          })
                        }}>
                    <Questions/>
                    {showModalHelper && <ContextHelp helpText={{ id: 3, what: <FormattedMessage id="inbox-helper" defaultMessage='You can switch between a Public Chat where all participants and speakers write, comment and discuss topics, and you can privately chat to one of the participants.'/> }} open={showModalHelper.open} anchorEl={showModalHelper.event} setShowModalHelper={setShowModalHelper}/>}
                  </span>
                            </p>
                            </div>
                        </div>
                        <div className="chat-tabs">
                            <Tabs
                                TabIndicatorProps={{ style: { background: colors.bgColor_tab_indicator } }}
                                onChange={handleChangeTab}
                                textColor="inherit"
                                indicatorColor="primary"
                                value={`${selectedTab}`}
                                sx={{
                                  width: '100%'
                                }}
                            >
                              <div className='inbox-helper-mobile'
                                  onClick={(e) => {
                                    setShowModalHelper({
                                      open: !showModalHelper.open,
                                      event: e.currentTarget
                                    })
                                  }}>
                    <Questions width='25' height='25'/>
                                {showModalHelper && <ContextHelp helpText={{ id: 3, what: <FormattedMessage id="inbox-helper" defaultMessage='You can switch between a Public Chat where all participants and speakers write, comment and discuss topics, and you can privately chat to one of the participants.'/> }} open={showModalHelper.open} anchorEl={showModalHelper.event} setShowModalHelper={setShowModalHelper}/>}
                  </div>

                                <Tab value="pub-chat"
                                     className="pub-chat"
                                     label={<FormattedMessage {...messages.public_chat} />}
                                     sx={{
                                       width: '50%',
                                       maxWidth: '50%'
                                     }}
                                />
                                <Tab className='pr-chat'
                                    value="pr-chat"
                                     label={ <FormattedMessage {...messages.private_chat} defaultMessage="Private Chat"/>}
                                     sx={{
                                       width: '50%',
                                       maxWidth: '50%'
                                     }}/>
                            </Tabs>
                        </div>
                        {selectedTab === 'pr-chat'
                          ? <Chats sendBird={sendBird} userMsg={userMsg} user={profileDetails} connecting={connecting}/>
                          : <PublicChat sb={sendBird} userInbox={connectedUserToSendBird}/>}
                    </div>
                )}
                {/* <SideBar onChange={handleChange} selectedprop={"inbox"} /> */}
            </div>

        </>
  )
}
