import React from 'react'
import { FormattedMessage } from 'react-intl'
import noAvatar from '../../../../../images/noAvatar.png'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Avatar from '../../../../../components/Avatart/Avatar'

const HeaderForMsg = ({ msg, operator, user, user2, imageUrl, showMsgDetail, msgId, setShowMsgDetail, setMsgId, publicChat = false, isOnline }) => {
  // hooks
  const navigate = useNavigate()
  // redux
  const isMessageOwnedByUser = msg?.sender?.userId === user?.userId
  const slug = useSelector(state => state.globalValueReducer.slug)
  const en = localStorage.getItem('en')
  const eventId = useSelector(state => state.globalValueReducer.eventId)
  return (
        <div className="msg-time"
             style={{ flexDirection: isMessageOwnedByUser ? 'row' : 'row-reverse' }}>
            {!!msg.updatedAt &&
                (
                    <p style={{
                      position: 'absolute',
                      bottom: isMessageOwnedByUser ? '10px' : '30px',
                      left: en ? '-35px' : '-55px',
                      fontSize: 11,
                      color: 'darkgray'
                    }}><FormattedMessage id="edited" defaultMessage="Edited"/></p>
                )}
            <p>{new Date(msg?.createdAt).getHours()}:{new Date(msg?.createdAt).getMinutes() < 10
              ? '0' + new Date(msg?.createdAt).getMinutes()
              : new Date(msg?.createdAt).getMinutes()}</p>
            {
                <h4>{ msg?.messageType === 'admin' ? <FormattedMessage id="admin-message" defaultMessage="Admin message"/> : isMessageOwnedByUser
                  ? <FormattedMessage id="you" defaultMessage="You"/>
                  : `${msg?.sender?.nickname}`}</h4>
            }

            {
                publicChat ? <div className={`chat-header-avatar ${msg?.messageType === 'file' ? 'chat-header-avatar-edit' : ''}`}
                >
                    {!isMessageOwnedByUser && <div><Avatar name={msg?.sender?.nickname} status_online={isOnline.find((value) => value?.userId === msg?.sender?.userId)}
                                                                                          url={imageUrl} id={msg?.sender?.metaData?.django_id}
                    /></div> }
                </div>
                  : <div className="chat-header-avatar cursor-pointer"
                 onClick={() => {
                   navigate(`/events/${eventId}/${slug}/participants/profile/`,
                     {
                       state: { profileId: msg?.sender.metaData.django_id }
                     })
                 }}
            >

                {
                    isMessageOwnedByUser
                      ? ''
                      : <img src={user2?.avatar?.download_url ? user2?.avatar?.download_url : noAvatar}
                               alt={msg?.sender?.nickname}/>}
            </div>
            }
            {
                ((msg?.messageType === 'admin' && operator) || isMessageOwnedByUser || msg.type)
                  ? <MoreVertIcon
                        style={{ alignSelf: ' flex-end' }}
                        onClick={() => {
                          setShowMsgDetail(!showMsgDetail)
                          setMsgId(msg?.messageId)
                        }}
                        cursor="pointer"
                    />
                  : ''
            }
        </div>
  )
}

export default React.memo(HeaderForMsg)
