import React from 'react'
import logo from '../../images/logo.png'
import { useNavigate } from 'react-router-dom'
import colors from '../../Colors.scss'

export default function HeadLogo () {
  const navigate = useNavigate()
  return (
      <div className='logo-container'
           onClick={() => {
             localStorage.removeItem('evId')
             navigate('/')
           }}

      >
        <a target='_blank' href="https://www.ost.ch/de/forschung-und-dienstleistungen/wirtschaft/ism-institut-fuer-strategie-und-marketing/wissenstransfer-innovation/wtt-young-leader-award" rel="noreferrer">
        <img src={logo} alt="logo" height={56} /></a>
        <h2 style={{
          color: colors.color_cards_background
        }}
        onClick={() => {
          localStorage.removeItem('evId')
          navigate('/')
        }}
        >WTT YOUNG LEADER AWARD 2023</h2>
      </div>
  )
}
