import React from 'react'
import UserPlaceholder from '../../../SVG/pages/eventAgenda/UserPlaceholder'
// import { FormattedMessage } from 'react-intl'

const PlaceholderTextViewAgenda = ({ people, title, count, activeAgenda }) => {
  return (
      <>
          {/* { */}
          {/*    title.toLowerCase() === 'speakers' */}
          {/*      ? <FormattedMessage id="speakers" defaultMessage="Speakers"/> */}
          {/*      : <FormattedMessage id="chair" defaultMessage="Chair"/> */}
          {/* } */}

        <div className="chair-card-container">
            { people.slice(0, count).map(
              (sp) => {
                return <div className="chair-card cursor-pointer"
                                key={sp.id}>
                        <div className='placeholder_text-event-agenda'>
                            {sp?.avatar?.download_url ? <img
                                src={sp?.avatar?.download_url}
                                alt={sp?.full_name}
                                title={sp?.full_name}
                                width="36px"
                                height="36px"
                            /> : <UserPlaceholder width='28' height="28"/>}
                            <p>{sp?.full_name}</p>
                        </div>
                    </div>
              }
            )}
            {people.length > count && <span className={`${activeAgenda ? 'span-white' : 'span-black'}`}> + {people.length - count } more</span> }
        </div>
      </>

  )
}

export default PlaceholderTextViewAgenda
