import React, { useEffect, useRef, useState } from 'react'
import parse from 'html-react-parser'
import ReactPlayer from 'react-player'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

const StreamContainer = ({ stream, showMenu }) => {
  // hooks
  const location = useLocation()
  const aspectRatioRef = useRef()
  // redux
  const { videoUrlEmbed, videoUrlHls } = useSelector(state => state.globalValueReducer.urlFromVideo)

  // state
  const [height, setHeight] = useState(0)
  const [orientation, setOrientation] = useState(window?.orientation)
  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    window.addEventListener('orientationchange', () => {
      if (window.outerHeight > window.outerWidth) {
        return setTimeout(() => {
          setOrientation(90)
          setOpenModal(true)
        })
      } else {
        return setTimeout(() => {
          setOrientation(0)
          setOpenModal(false)
        })
      }
    })
  }, [])

  window.addEventListener('resize', () => {
    setTimeout(() => {
      setHeight(aspectRatioRef?.current?.clientWidth / (1920 / 1080))
    }, 1000)
  })

  useEffect(() => {
    setTimeout(() => {
      setHeight(aspectRatioRef?.current?.clientWidth / (1920 / 1080))
    }, 500)
  }, [showMenu])

  return (
        <>
            {
                (!location.pathname.includes('video') || videoUrlEmbed === 'false' && videoUrlHls === 'false') ? stream?.items[0]?.status === 'PUBLISHED'

                  ? stream?.items[0].embed_code
                    ? <div ref={aspectRatioRef}
                                   className={`${orientation === 90 && openModal ? 'full-screen' : 'responsive-iframe'}`}>
                                {parse(
                                    `<div style="width: 100%; height: 100%; aspect-ratio: ${height ? 0 : '1920 / 1080'};   " ><iframe height="100%" width="100%" className=${orientation !== 90 && !openModal && 'responsive-iframe'} src=${stream?.items[0].embed_code}  allowfullscreen frameborder="0" marginwidth="0" marginheight="0" scrolling="no" allowtransparency="true"></iframe></div>`
                                )}
                            </div>

                    : <div ref={aspectRatioRef}
                                   className={` ${orientation === 90 && openModal ? 'full-screen' : 'responsive-iframe'}`}>
                                <ReactPlayer
                                    style={{ aspectRatio: height ? 0 : '1920 / 1080' }}
                                    url={stream?.items[0]?.hls_url}
                                    controls={true}
                                    width="100%"
                                    height={window.outerWidth < 1024 ? height : '100%'}
                                />
                            </div>

                  : <div className="responsive-iframe">
                            {/* '1920/1080' */}
                            <div ref={aspectRatioRef}
                                 style={{ width: '100%', height: '100%', aspectRatio: height ? 0 : '1920 / 1080' }}>
                                <ReactPlayer
                                    url={'https://vod.upstream-cloud.ch/51cae53c-fc43-4321-9faa-6c1115d03672/hls/63806ecad645094bd8c42899/63806ecad645094bd8c42899.m3u8'}
                                    controls={true}
                                    width="100%"
                                    height="100%"
                                    light={'/poster_001.png'}
                                    playing={true}
                                />
                                {/* </span> */}</div>
                            <div className="layout-not-authorized">
                                {parse(stream?.items[0]?.status_message)}</div>
                        </div>

                  : videoUrlEmbed

                    ? <div ref={aspectRatioRef}
                               className={` ${orientation === 90 && openModal ? 'full-screen' : 'responsive-iframe'}`}>
                            {parse(
                                `<div style="width: 100%; height: 100%; aspect-ratio: ${height ? 0 : '1920 / 1080'};   " ><iframe height="100%"  width="100%" className=${orientation !== 90 && !openModal && 'responsive-iframe'} src=${videoUrlEmbed} allowfullscreen frameborder="0" marginwidth="0" marginheight="0" scrolling="no" allowtransparency="true"></iframe></div>`
                            )}
                        </div>

                    : <div ref={aspectRatioRef}
                               className={` ${orientation === 90 && openModal ? 'full-screen' : 'responsive-iframe'}`}>
                            <ReactPlayer
                                style={{ aspectRatio: '1920 / 1080', width: '100%' }}
                                url={videoUrlHls}
                                controls={true}
                                width="100%"
                                height="100%"
                            />
                        </div>
            }
        </>
  )
}

export default StreamContainer
